@import url(https://fonts.googleapis.com/css?family=Comforter+Brush:regular);@import url(https://fonts.googleapis.com/css?family=Codystar:300,regular);
@import url(https://fonts.googleapis.com/css?family=Butcherman:regular);


.Footer-container {
  position: relative;
}

.Footer-container > hr {
   border: 1px solid var(--lightgray);
}

.footer {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}

.social-links {
 display: flex;
 gap: 4rem;
}

.social-links > img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;

}

.logo-f {
    width: 10rem;
    display: inline-flex;
    justify-content: center;
}

.blur-f-1{
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 5rem;
    filter: blur(200px);
    background: red;
}

.blur-f-2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 5rem;
    filter: blur(200px);
    background: rgb(248, 109, 230);
}

.kanayo{
font-family: 'Comforter Brush', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
background: linear-gradient(to right, #00ffff 2%, #dd4c4f 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
display: inline-block;
font-size: 35px;
}

.line{
    border: 1px solid orangered;
 
}

@media screen and (max-width: 768px)
{

.logo-f>img{
    position: relative;
    width: 15rem;
   
    align-self: center;

}

.kanayo{
    font-size: x-large;
}



}