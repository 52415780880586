.container{
    
padding: 0rem 2rem;
display: grid;
grid-template-columns: 1fr 3fr 1fr;
}

.wrapper{
    
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
    top: 20.25px;
}

.wrapper>img{
    /* top: 15px; */
    position:absolute;
    width: 25rem;
    bottom:0;
}

.blueCircle{
    /* top: 0.5px; */
    width: 25rem;
    height: 25rem;
    border-radius: 50%;
    z-index: -99;
    position: absolute;
    bottom: 0;
    background: linear-gradient(135.74deg, #0bb5ff 14.09%, #6dd3fe 83.81%);
}

.cart2{
    position: absolute;
    bottom: 15%;
    right: -8%;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.cart2>svg{
    width: 20px;
    height: 20px;
    background: white;
    padding: 10px;
    border-radius: 50%;
    border: 6px solid var(--black);
}

.signup{
    display: flex;
    gap: 1rem;
    align-items: center;
    background: white;
    padding: 10px;
    font-size: .8rem;
    border-radius: 15px;
    box-shadow: var(--shadow1);

}
.signup>:first-child{
    display: block;
    width: 6rem;
}
.signup>:nth-child(2)
{
    display: flex;
    height: 20px;
    width: 20px;
    padding: 5px;
    border: 1px solid skyblue;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.h_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.text1{
    text-transform: uppercase;
    font-size: 1.5rem;
    width: min-content;
    display: block;
    font-weight: 600;
}
.text2{
    display: flex;
    flex-direction: column;
    width: min-content;
    color: black;
}

.text2>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}

.text2>:last-child{
    display: block;
}

.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;
}

.traffic>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}

.customers{
    display: flex;
    flex-direction: column;
    text-align: right;
}

.customers>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
}

@media screen and (max-width: 856px){
    .text1, .text2>span:nth-of-type(2){
        font-size: .9rem;
        text-align: justify;
    }

    
  .traffic>span:nth-child(1){
        text-align: left;
        font-size: 1.5rem;
    }

    .traffic>span:nth-child(2){
        text-align: left;
    
    }

    .customers>span:nth-child(1){
        text-align: right;
        font-size: 1.5rem;
    }

    .customers>span:nth-child(2){
        text-align: right;
      
    }

    .text2{
        padding-top: 10rem;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1rem;
    
    }

.wrapper{
    display: flex;
    justify-content: center;
}

    /* .blueCircle{
        position: absolute;
        width: 20%;
        height:30%;
    }
    .wrapper>img{
        width: 20%;
        height: 30%;
    } */

    .container{
        grid-template-areas: 'left center center'
        'right right right';
    }

    .container>:first-child{
        grid-area: left;
        grid-template-rows: none;
        gap: 8rem;
    }
    .container>:nth-child(2){
        grid-area: center;
    }

    .container>:nth-child(3){
        grid-area: right;
        display: flex;
        justify-content: space-around;
        margin: 2rem 0rem;
    }
}


@media screen and (max-width: 640px){
    .blueCircle{
    
        right: 2px;
        top: 2rem;
     /* margin-right: 0.5rem; */
       width: 15.5rem;
       height: 16rem;
     
    }

.wrapper>img{
    top: 3rem;
    position: absolute;
    display: flex;
    right: 2px;
    justify-items: center;
 /* margin-right: 0.5rem; */
   width: 15.5rem;
}
.cart2{
    transform: scale(.6);
    margin-left: 12px;;
    bottom: 0;
    top: 4rem;
}



.traffic>:nth-child(2){
   /* display: flex-start;
   justify-self: left; */
}
}